.AuthBody{
    background: #EDF0F4;
}
.InnerAuthBody{
    border: 1px solid #00000061 !important;
    border-radius: 10px !important;
    padding: 30px !important;
    text-align: center;
    max-width: 25rem;
    position: relative;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.27);
    -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.27);
    -moz-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.27);
    width: 25rem;
    max-width: 25rem;
}
.LoginButton{
    width: max-content;
    display: flex;
    position: relative;
    left: 40%;
}
.logo{
    position: relative;
    margin-bottom: 12px;
}
.zohomailLogo{
    width: 70px;
    height: 42px;
    left: 40%;
}
.gmailLogo{
    width: 42px;
    height: 42px;
    left: 42%;
}