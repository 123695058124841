.spinner-style {
    height: 64px !important;
    width: 64px !important;
    z-index: 9999;
    position: absolute;
    /* filter: blur(50px); */
  }
  .loaderDiv{
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 100000000;
    top: 0px;
    left: 0;
    background: #00000099;
  }
  .Loader{
    align-items: center;
  }